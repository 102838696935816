


































































































import useMisc from "@/use/misc";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const model = reactive({
      locationId: "",
      isEnabled: false,
      registrationFormType: "participant registration",
      registrationFormPayment: "payment handled by galop",
      hotelPaymentConfirm: "",
      sendRegistrationToEmail: false,
      email: "",
      additionalInformation: "",
    });

    const state = reactive({
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      valid: false,
      items: [],
    });

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 409:
          return `${root.$tc(
            "panel.event.modules.accommodationModule.add.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchLocations = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("location")
        .then(({ data: { locations } }) => {
          state.items = locations.map((location: any) => ({
            id: location.id,
            name: location.name,
            city: location.city,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchLocations);

    const onSubmit = (action: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        locationId: model.locationId,
        isEnabled: model.isEnabled,
        registrationFormType: "participant registration",
        registrationFormPayment: model.registrationFormPayment,
        hotelPaymentConfirm:
          model.registrationFormPayment === "payment handled by hotel"
            ? model.hotelPaymentConfirm
            : null,
        sendRegistrationToEmail: model.sendRegistrationToEmail,
        email: model.sendRegistrationToEmail ? model.email : null,
        additionalInformation: model.additionalInformation || null,
      };

      state.loading = true;

      axiosInstance
        .post(`event/${root.$route.params.id}/hotel`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "panel.event.modules.accommodationModule.add.success"
            ),
          });
          if (action === "new") {
            model.locationId = "";
            model.isEnabled = false;
            model.registrationFormType = "participant registration";
            model.registrationFormPayment = "payment handled by galop";
            model.hotelPaymentConfirm = "";
            model.sendRegistrationToEmail = false;
            model.email = "";
            model.additionalInformation = "";
          } else {
            goBack("panel.event.view.accommodationModule.list");
          }
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, state, rules, onSubmit };
  },
});
